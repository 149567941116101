import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';

// Style and SEO
import { Col, Grid, Row, styled, up, css } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';

import BannerHome from 'components/CustomComponents/Banners/BannerHome';
import BgSecondary from 'components/CustomComponents/Backgrounds/BgSecondary';
import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';

import bannerMobile from './images/home-page-LunchOnUs-Mobile@1x.jpg';
import bannerMobile2x from './images/home-page-LunchOnUs-Mobile@2x.jpg';
import bannerTablet from './images/Home-page-LunchOnUs-Tablet@1x.jpg';
import bannerTablet2x from './images/Home-page-LunchOnUs-Tablet@2x.jpg';
import bannerDesktop from './images/LunchOnUs@1x.jpg';
import bannerDesktop2x from './images/LunchOnUs@2x.jpg';

import Step1 from './images/Icons@1x_0003_icon-Camera-@1x-.png';
import Step2 from './images/Icons@1x_0002_icon-SalesClaim-@1x-.png';
import Step3 from './images/Icons@1x_0001_icon-Points-@1x-.png';
import Step4 from './images/Icons@1x_0000_icon-Rewards-@1x-.png';

import Video from './images/homepage-video-@1x.png';
import Products from './images/homepage-participatingproducts-@1x.png';

import Video2x from './images/homepage-video-@2x.png';
import Products2x from './images/homepage-participatingproducts-@2x.png';
import footer2x from '../../theme/logo-footer-@2x.png';

const PageContainer = styled.div`
  padding-bottom: 66px;
  position: relative;

  @keyframes modal-video {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }
    to {
      transform: translate(0, 0);
    }
  }
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    transition: opacity 0.3s ease-out;
  }
  .modal-video-effect-exit {
    opacity: 0;
  }
  .modal-video-effect-exit .modal-video-movie-wrap {
    transform: translate(0, 100px);
  }
  .modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  .modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box;
    }
  }
  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    transform: translate(0, 0);
    transition: transform 0.3s ease-out;
  }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
  }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px;
    }
  }
  .modal-video-close-btn:before {
    transform: rotate(45deg);
  }
  .modal-video-close-btn:after {
    transform: rotate(-45deg);
  }
  .modal-video-close-btn:before,
  .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
  }

  .footer {
    background-color: #dfe9e6;
    position: absolute;
    bottom: 0;
    min-height: 66px;
    text-align: center;

    ${up(
      'md',
      css`
        text-align: right;
      `
    )};
  }
`;

const VideoBanner = styled.div`
  background-image: url('${Video}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 360px;
  cursor: pointer;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url('${Video2x}');
  }
`;

const ProductsBanner = styled.div`
  background-image: url('${Products}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 360px;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url('${Products2x}');
  }
`;

const HomePage = ({ settings }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <PageContainer>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <BannerHome
          bannerMobile={bannerMobile}
          bannerMobile2x={bannerMobile2x}
          bannerTablet={bannerTablet}
          bannerTablet2x={bannerTablet2x}
          bannerDesktop={bannerDesktop}
          bannerDesktop2x={bannerDesktop2x}
        />
        <BgSecondary className="banner-placement">
          <Grid className="text--center">
            <Row justifyContent="center">
              <Col md={10} style={{ borderBottom: '1px #979797 solid' }}>
                <p>
                  Reach your store points target between 1st September 2024 and
                  31st October 2024 to receive a $30 Uber Eats Digital Gift Card
                  and enjoy Lunch on Us!
                </p>
                <p>See Promotions Page for more details!</p>
                <p>*T's and C's apply.</p>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md={10}>
                <h1 className="text--uppercase">
                  Welcome to the FujiFilm X Rewards Program
                </h1>
                <p>
                  Every time you sell a participating product, submit a sales
                  claim by entering the product details, upload the invoice, and
                  you will earn points. Points vary by product sold, plus you
                  will have the opportunity to earn bonus points on selected
                  products for completing training modules. You will be
                  selecting the reward of your choice in no time!
                </p>
              </Col>
            </Row>
          </Grid>
        </BgSecondary>

        <Grid>
          <Row justifyContent="center" className="spacer">
            <Col>
              <h1 className="text--center text--uppercase">How it works</h1>
            </Col>
          </Row>
          <Row className="spacer--bottom text--center" justifyContent="center">
            <Col xs={12} md={3}>
              <img src={Step1} alt="Step 1" />
              <h2 className="text--primary">STEP 1</h2>
              <p>
                Sell Fujifilm X and GFX cameras
                <br />
                and XF, XC and GF lenses
              </p>
            </Col>
            <Col xs={12} md={3}>
              <img src={Step2} alt="Step 2" />
              <h2 className="text--primary">STEP 2</h2>
              <p>
                Submit your sales claim,
                <br />
                including invoice to earn points
              </p>
            </Col>
            <Col xs={12} md={3}>
              <img src={Step3} alt="Step 3" />
              <h2 className="text--primary">STEP 3</h2>
              <p>
                Look out for opportunities
                <br />
                to boost your points
              </p>
            </Col>
            <Col xs={12} md={3}>
              <img src={Step4} alt="Step 4" />
              <h2 className="text--primary">STEP 4</h2>
              <p>
                Claim your
                <br />
                rewards!
              </p>
            </Col>
          </Row>
        </Grid>
        <BgSecondary>
          <Grid className="spacer">
            <Row justifyContent="center">
              <Col xs={12} md={6}>
                <VideoBanner onClick={() => setOpen(true)} />
                <ModalVideo
                  channel="youtube"
                  autoplay
                  isOpen={isOpen}
                  videoId="hkf0awTothc"
                  onClose={() => setOpen(false)}
                />
              </Col>
              <Col xs={12} md={6}>
                <Link to="/products">
                  <ProductsBanner />
                </Link>
              </Col>
            </Row>
          </Grid>
        </BgSecondary>
      </PrivateComponent>
      <Grid>
        <Row justifyContent="center">
          <Col xs={12} className="footer text--right">
            <img src={footer2x} alt="Fujifilm" height="57px" />
          </Col>
        </Row>
      </Grid>
    </PageContainer>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
