import React from 'react';

import { css, styled, up } from '@smooth-ui/core-sc';

const BannerWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const BannerContainer = styled.div`
  ${props =>
    props.bannerMobile
      ? `background-image: url(${props.bannerMobile});`
      : null};
  align-items: center;
  background-color: #dfe9e6;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 15em;
  justify-content: flex-start;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    ${props =>
      props.bannerMobile || props.bannerMobile2x
        ? `background-image: url(${props.bannerMobile2x ||
            props.bannerMobile});`
        : null};
  }
  ${up(
    'sm',
    css`
      ${props =>
        props.bannerTablet
          ? `background-image: url(${props.bannerTablet});`
          : null};

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        ${props =>
          props.bannerTablet || props.bannerTablet2x
            ? `background-image: url(${props.bannerTablet2x ||
                props.bannerTablet});`
            : null};
      }
    `
  )}
  ${up(
    'lg',
    css`
      ${props =>
        props.bannerDesktop
          ? `background-image: url(${props.bannerDesktop});`
          : null};
      background-position: center top;
      height: 15em;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        ${props =>
          props.bannerDesktop || props.bannerDesktop2x
            ? `background-image: url(${props.bannerDesktop2x ||
                props.bannerDesktop});`
            : null};
      }
    `
  )}
  ${up(
    'xl',
    css`
      height: 20em;
    `
  )}
`;

const BannerHome = props => (
  <BannerWrapper className="banner-wrapper">
    <BannerContainer {...props} />
  </BannerWrapper>
);

export default BannerHome;
