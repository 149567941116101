import { styled } from '@smooth-ui/core-sc';

const BgSecondary = styled.section`
  align-items: center;
  background-color: #dfe9e6;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
`;

export default BgSecondary;
